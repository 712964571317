import React from "react";
import "../assets/css/Home.css";
import { Row, Col } from "react-bootstrap";
import {
    MDBCarousel,
    MDBCarouselInner,
    MDBCarouselItem,
    MDBContainer,
  } from "mdbreact";

export const RE = () => {
    return (
        <MDBContainer>
        <h4 className="heading-color">BBIS Commercial Heating Reviews </h4>
          <MDBCarousel
            activeItem={1}
            length={2}
            slide={true}
            showControls={false}
            showIndicators={true}
            multiItem
          >
            <MDBCarouselInner>
              <Row>
                <MDBCarouselItem itemId="1">
                  <div class="row">
                    <div class="col-lg-4 col-md-4 col-sm-4">
                      <div className="review">
                        <div className="review-title">
                          Positive: Punctuality, Quality, Responsiveness, Value
                        </div>
                        <span className="review-rating">
                          <span className="fa fa-star gold-star"></span>
                          <span className="fa fa-star gold-star"></span>
                          <span className="fa fa-star gold-star"></span>
                          <span className="fa fa-star gold-star"></span>
                          <span className="fa fa-star gold-star"></span>
                        </span>
                        <div className="review-content">
                          <div className="review-comment">
                            Thank you to the team at BBIS for an impeccable
                            service. Not only have you solved all the problems
                            in my properties which have been incredibly
                            stressful, but you've responded at the drop of a
                            hat. The team are friendly and helpful and I
                            couldn't recommend them enough.
                          </div>
                        </div>
                        <div className="author">Michelle Jackson</div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-4">
                      <div className="review">
                        <div className="review-title">First class business</div>
                        <span className="review-rating">
                          <span className="fa fa-star gold-star"></span>
                          <span className="fa fa-star gold-star"></span>
                          <span className="fa fa-star gold-star"></span>
                          <span className="fa fa-star gold-star"></span>
                          <span className="fa fa-star gold-star"></span>
                        </span>
                        <div className="review-content">
                          <div className="review-comment">
                            I have been using BBIS for many years now and I have
                            to say they are a first class business with
                            outstanding service. They are trustworthy and
                            friendly and have always helped me when needed.
                            Recommend highly.
                          </div>
                        </div>
                        <div className="author">Christina Panayi</div>
                      </div>
                    </div>

                    <div class="col-lg-4 col-md-4 col-sm-4">
                      <div className="review">
                        <div className="review-title">
                          I could not recommend them enough
                        </div>
                        <span className="review-rating">
                          <span className="fa fa-star gold-star"></span>
                          <span className="fa fa-star gold-star"></span>
                          <span className="fa fa-star gold-star"></span>
                          <span className="fa fa-star gold-star"></span>
                          <span className="fa fa-star gold-star"></span>
                        </span>
                        <div className="review-content">
                          <div className="review-comment">
                            I have been using BBIS for all of our plant
                            room/commercial boiler needs for many years, and we
                            have found them to be incredibly efficient, pro
                            active, knowledgeable, helpful and always leaving
                            our plant rooms in good condition. I could not
                            recommend them enough.
                          </div>
                        </div>
                        <div className="author">Graham Garland</div>
                      </div>
                    </div>
                  </div>
                </MDBCarouselItem>
                <MDBCarouselItem itemId="2">
                  <div class="row">
                    <div class="col-lg-4 col-md-4 col-sm-4">
                      <div className="review">
                        <div className="review-title">
                          Excellent workmanship
                        </div>
                        <span className="review-rating">
                          <span className="fa fa-star gold-star"></span>
                          <span className="fa fa-star gold-star"></span>
                          <span className="fa fa-star gold-star"></span>
                          <span className="fa fa-star gold-star"></span>
                          <span className="fa fa-star gold-star"></span>
                        </span>
                        <div className="review-content">
                          <div className="review-comment">
                            I have been a customer of BBIS for several years;
                            they always provide an excellent and reliable
                            service and have a great can-do attitude. I have
                            always found them to be professional, punctual, tidy
                            along with excellent workmanship - highly
                            recommended.
                          </div>
                        </div>
                        <div className="author">Adam Smith</div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-4">
                      <div className="review">
                        <div className="review-title">
                          Highly recommend them
                        </div>
                        <span className="review-rating">
                          <span className="fa fa-star gold-star"></span>
                          <span className="fa fa-star gold-star"></span>
                          <span className="fa fa-star gold-star"></span>
                          <span className="fa fa-star gold-star"></span>
                          <span className="fa fa-star gold-star"></span>
                        </span>
                        <div className="review-content">
                          <div className="review-comment">
                            Great company that deliver great service! Would
                            highly recommend them.
                          </div>
                        </div>
                        <div className="author">Maria Keles</div>
                      </div>
                    </div>

                    <div class="col-lg-4 col-md-4 col-sm-4">
                      <div className="review">
                        <div className="review-title">
                          I could not recommend them enough
                        </div>
                        <span className="review-rating">
                          <span className="fa fa-star gold-star"></span>
                          <span className="fa fa-star gold-star"></span>
                          <span className="fa fa-star gold-star"></span>
                          <span className="fa fa-star gold-star"></span>
                          <span className="fa fa-star gold-star"></span>
                        </span>
                        <div className="review-content">
                          <div className="review-comment">
                            I have been using BBIS for all of our plant
                            room/commercial boiler needs for many years, and we
                            have found them to be incredibly efficient, pro
                            active, knowledgeable, helpful and always leaving
                            our plant rooms in good condition. I could not
                            recommend them enough.
                          </div>
                        </div>
                        <div className="author">Graham Garland</div>
                      </div>
                    </div>
                  </div>
                </MDBCarouselItem>
              </Row>
            </MDBCarouselInner>
          </MDBCarousel>
        </MDBContainer>
  );
};